import { useEffect, useState } from 'react';
import './App.css';

function App() {

  const user = window.location.pathname.slice(1).split("/")[0];

  if(user === "") {
    return (
      <Home />
    );
  }
  else {
    return <RSVP user={user}/>
  }
}

function Home() {

  const [code, setCode] = useState("");

  const goToRsvp = (e) => {
    e.preventDefault();
    window.location.pathname=`/${code.trim()}`
    return false;
  }

  return <div className='homepage center-vertically'>
    <div className='wrapper'>
      <img className='header-img' src='/venue.jpg' alt=''/>
        <h1>Khadija and Nicholas' Wedding</h1>
        <p>Please enter the code inside the invitation below, or scan the QR code.</p>
        <form onSubmit={goToRsvp} >
          <label htmlFor="id" name="id" className='sr-only'>Code</label>
          <div>
            <input id="id" type='text' defaultValue={code} onInput={e=>setCode(e.target.value)} placeholder='enter code here'/> 
          </div>
          <input type='submit' />
        </form>
      </div>
    </div>
}

function RSVP({user}) {
  const [invitation, setInvitation] = useState(null)
  const [invitationFetchErr, setInvitationFetchErr] = useState(null)

  const setEmail = (email) => {
    setInvitation(old => ({...old, email}))
  }

  useEffect(() => {
    if(invitation !== null) {
      return;
    }
    
    fetch(`${process.env.REACT_APP_API_URL}/invitations/${user}`)
    .then(resp => {
      if(resp.status === 200) {
        return resp.json();
      } else {
        setInvitationFetchErr("404: Not Found")
      }
    })
    .then(data => setInvitation(data))
  }, [user, invitation, setInvitation])

  return <div className='center-vertically rsvp'>
    { invitation===null && invitationFetchErr===null && <Loading/>}
    { invitationFetchErr!==null && <h1>{invitationFetchErr}</h1>}
    { invitation !== null && invitationFetchErr==null && <RSVPForm uid={user} name={invitation.name} events={invitation.events} email={invitation.email} setEmail={setEmail}/> }
  </div>
}

function Loading() {
  return <div>Please wait a moment while the page loads</div>
}

function RSVPForm({name, uid, email, events, setEmail}) {
  const [formState, setFormState] = useState(null);
  const [didOverwrite, setDidOverwrite] = useState(null);
  const [eventResponses, setEventResponses] = useState(events.map(event => ({event_id: event.name, accepted: true})));
  const setEventResponse = (name, accepted) => {
    setEventResponses(eventResponses =>
      [{accepted, event_id: name}, ...eventResponses.filter(resp => resp.event_id !== name)]
    )
  }
  const [message, setMessage] = useState("");

  const submit = (e) => {
    e.preventDefault();
    setFormState("submitting");

    const data = {
      guest_id: uid,
      additional_message: message,
      responses: eventResponses,
      email
    }

    fetch(`${process.env.REACT_APP_API_URL}/rsvp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }).then(resp => {
      if(resp.status !== 200) {
        setFormState("error")
      } else {
        return resp.json();
      }
    }).then(data => {
      if(data["success"] !== true) {
        setFormState("error")
      } else {
        setFormState("success");
        setDidOverwrite(data["overwritten"]);
      }
    })

    return false;
  }

  if(formState==="submitting") {
    return <p>Sending Data...</p>
  }
  if(formState==="error"){
    return <div><h1>Something went wrong...</h1><p>Please refresh and try again.</p></div>

  }
  if(formState==="success") {
    return <div>
        <h1>Response Recorded!</h1>
        <p>You should recieve email confirmation.</p>
        {didOverwrite && <p>This response has overwritten your previous response.</p>}
      </div>
  }

  return <div className='wrapper'>
    <img className='header-img' src='/venue.jpg' alt=''/>
    <h1>R.S.V.P. to Khadija and Nicholas' Wedding</h1>
    <p>Dear {name}, please fill in this form to let us know if you'll be celebrating with us.</p>
      <form onSubmit={submit}>
      <div className='input-group'>
          <div><label htmlFor="email">Email Address</label></div>
          <input type='email' id="email" name='email' value={email} onInput={e => setEmail(e.target.value)}/>
        </div>

        {/* Events */}
        {events.map((event, idx) =>
          <EventCheckbox key={idx} name={event.name} date={event.date} setResponse={(accepted) => setEventResponse(event.name, accepted)} />
        )}


        <div className='input-group'>
          <div><label htmlFor="message">Additional Notes</label></div>
          <textarea id="message" name='message' cols={60} rows={5} placeholder='(E.g. if only some of the invited guests will be attending.)' value={message} onInput={e => setMessage(e.target.value)}/>
        </div>

        <div className='input-group'>
          <input type='submit' />
        </div>
      </form>
    </div>
}

function EventCheckbox({name, date, setResponse}) {
  const [answer, setAnswer] = useState("yes");

  const updateAnswer = e => {
    const accepted = e.target.value==="yes";
    setAnswer(accepted ? "yes" : "no");
    setResponse(accepted)
  }

  return (
    <div className='radio input-group'>
      <div className='label'>Will you be attending the {name} on {date}?</div>
      <input type='radio' id={`${name}-yes`} name={name} value="yes" onChange={updateAnswer} checked={answer==="yes"} />
      <label htmlFor={`${name}-yes`}>Yes</label>
      <input type='radio' id={`${name}-no`} name={name} value="no" onChange={updateAnswer} checked={answer==="no"} />
      <label htmlFor={`${name}-no`}>No</label>
    </div>
  )
}

export default App;
